// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { createUseStyles } from 'react-jss'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

// 
// ─── IMPORTING SVG ───────────────────────────────────────
//
import groupeBk from '../assets/static/logo-officiel-white.svg'

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../contexts/Api';
import { getValue } from '../contexts/Utils';

//
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import HeroBanner from '../components/HeroBanner';
import ActuCarousel from '../components/ActuCarousel';
import Title from '../components/Title';
import ModalPwa from '../components/Modals/ModalPWA';
import Wheel from '../components/Wheel/Wheel';
import ModalCgu from '../components/Modals/Cgu/ModalCgu';
import Button from '../components/Button';
import ModalNewPost from '../components/Modals/NewPost/ModalNewPost';
import EventModal from '../components/Modals/EventModal';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Home = () => {
  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const showEvents = localStorage.getItem('christmas_event');

  // 
  // ───  STYLE DEFINITION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    socialsContainer: {
      padding: '0 30px',
      marginBottom: 10
    },
    socials: {
      display: 'flex',
      margin: '0 auto',
      justifyContent: 'space-around',
      alignItems: 'center',

      '& svg:first-child': {
        fontSize: 50,
        color: 'white'
      },
    },
    iconInside: {
      color: 'var(--orange-primary)'
    },
    groupeBKContainer: {
      width: 30,
      height: 30,
      background: 'black',
      padding: 8,
      borderRadius: '50%',

      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      }
    },
    cguContainer: {
      textAlign: 'center',
      margin: 20,
      fontStyle: 'italic',
      fontSize: 12,

      '& a': {
        color: 'var(--orange-primary)',
        textDecoration: 'underline'
      }
    }
  })

  const classes = useStyle()

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [homeBanner, setHomeBanner] = useState('')
  // User gain_entry
  const [gainEntry, setGainEntry] = useState({})
  // User Cgu
  const [modalCgu, setModalCgu] = useState(false)
  // Show modal
  const [showPopover, setShowPopover] = useState({ open: false, data: {} });
  // Event modal
  const [modalEvent, setModalEvent] = useState(showEvents ? false : true)

  //
  // ─── GET HOME BANNER ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchHomeBanner = async () => {
      const getHomeBanner = await Api({
        endpoint: '/home',
        method: 'GET',
      });

      if (getHomeBanner.success) {
        setHomeBanner(getHomeBanner.data);
      } else {
        setHomeBanner('');
      }
    };
    fetchHomeBanner();
  }, []);

  //
  // ─── GET USER GAIN INFOS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUserGainEntry = async () => {
      let getUserGainEntry = await Api({
        endpoint: '/users',
        query: { fields: ["JSON_EXTRACT(gain_entry, '$') as gain_entry", 'points'], query_user_id: userData.user_id },
        method: 'GET',
      });
      if (getUserGainEntry.success) {
        if (getUserGainEntry.data.gain_entry) getUserGainEntry.data.gain_entry = JSON.parse(getUserGainEntry.data.gain_entry)
        setGainEntry({ gain_entry: getValue(getUserGainEntry, ['data', 'gain_entry', 'gain_entry']), points: getValue(getUserGainEntry, ['data', 'points']), brand_id: getValue(getUserGainEntry, ['data', 'gain_entry', 'brand_id']) });
      } else {
        setGainEntry({ gain_entry: 0 });
      }
    };
    if (!Object.keys(gainEntry).length) fetchUserGainEntry();
  }, [userData]);

  //
  // ─── GET USER CGU INFOS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUserCgu = async () => {
      const cguUser = await Api({
        endpoint: '/users',
        query: { fields: ["cgu", "role"], query_user_id: userData.user_id },
        method: 'GET',
      });
      if (cguUser.success) {
        if (!cguUser.data.cgu && cguUser.data.role == 2) setModalCgu(true)
      } else {
        setModalCgu(false);
      }
    };
    fetchUserCgu();
  }, []);

  // 
  // ─── HANDLE USER GAIN ENTRY ───────────────────────────────────────
  //
  const handleGainEntry = async () => {
    // On garde le brand_id, qui sera remplacé lors du prochain scan manager
    const gain_entry = { gain_entry: 0, brand_id: gainEntry.brand_id }
    const updateGainEntry = await Api({
      data: { fields: { gain_entry: JSON.stringify(gain_entry) }, user_edit: userData.user_id },
      endpoint: '/users',
      method: 'PUT',
    });

    if (updateGainEntry.success) {
      setGainEntry({ gain_entry: 0, brand_id: gainEntry.brand_id });
    } else {
      console.log(updateGainEntry)
    }
  }

  //
  // ─── MODAL NEW POST ───────────────────────────────────────
  //
  useEffect(() => {
    const getNewPost = async () => {
      const newPostPopUp = localStorage.getItem('newPostPopUp');
      const localData = JSON.parse(newPostPopUp);
      const posts = await Api({ endpoint: '/notifications', method: 'GET' });
      if (localData === null || (!localData.open && localData.post_id !== posts.data.post_id && posts.success)) {
        if (posts.data.brand_id && posts.data.brand_id !== userData.brand_id) return;
        setShowPopover({ open: true, data: posts.data });
        return;
      }
    }
    if (userData.role === 2) getNewPost()
  }, []);

  const handlePopoverClose = () => {
    setShowPopover(false);
    const popupData = { open: false, post_id: showPopover.data.post_id };
    localStorage.setItem('newPostPopUp', JSON.stringify(popupData));
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return gainEntry && <>
    {/* POP UP TO INSTALL PWA */}
    {window.innerWidth > 600 && userData.role !== 'super-admin' && <ModalPwa open={true} />}
    {/* POP UP CGU */}
    {modalCgu && <ModalCgu open={modalCgu} onClose={() => setModalCgu(false)} user_id={userData.user_id} />}
    {/* POP UP EVENTS */}
    {modalEvent && <EventModal open={modalEvent} onClose={() => { setModalEvent(false) }} user_id={userData.user_id} />}
    {/* POP UP NEW POST */}
    {showPopover.open && <ModalNewPost open={showPopover.open} post={showPopover.data} onClose={() => handlePopoverClose()} />}
    {/* POP UP CGU */}
    <HeroBanner link={`home/banner/${homeBanner}`} />

    {(gainEntry.gain_entry === 1) && <Wheel afterSpin={(prop) => setGainEntry(prop)} brand_id={gainEntry.brand_id} gain_entry={gainEntry.gain_entry} updateGainEntry={handleGainEntry} />}
    {/* <LoyaltyPointsBar user_points={gainEntry.points} /> */}

    <Title text="L'actualité du Groupe BK" />
    <ActuCarousel />

    <Title text="Nos enseignes" />
    <Button className="button cta" text="Découvrez nos enseignes" href='/enseignes' />


    <Title text="Retrouvez nous sur :" tag='2' />
    <div className={classes.socialsContainer}>
      <div className={classes.socials}>
        <a href='https://www.groupebk.com/' rel='noreferrer' target="_blank" className={classes.groupeBKContainer}><img src={groupeBk} alt='Logo groupe bk' /></a>
        <a href='https://www.instagram.com/groupe.bk/?hl=fr' rel='noreferrer' target="_blank">
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faInstagram} transform={'shrink-4'} className={classes.iconInside} />
          </span>
        </a>
        <a href='https://www.facebook.com/groupebk/' rel='noreferrer' target="_blank">
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faFacebookF} transform={'shrink-6'} className={classes.iconInside} />
          </span></a>
        <a href='https://www.linkedin.com/company/groupebk/mycompany/' rel='noreferrer' target="_blank">
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faLinkedinIn} transform={'shrink-6'} className={classes.iconInside} />
          </span>
        </a>
        <a href='https://www.youtube.com/channel/UClSZOHH3ixe-D20X0k9wseg' rel='noreferrer' target="_blank">
          <span className="fa-layers fa-fw fa-3x">
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faYoutube} transform={'shrink-6'} className={classes.iconInside} />
          </span>
        </a>
      </div>
    </div>

    <div className={classes.cguContainer}>Une copie du règlement du Jeu de la roue est disponible sur demande écrite à l’adresse <a href='mailto:marketing@groupebk.com'>marketing@groupebk.com</a>.</div>
  </>
};

export default Home;