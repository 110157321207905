// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
//import EventImg from '../../assets/static/popup_new_year.jpg'
import EventImg from '../../assets/static/christmas.jpg'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EventModal = ({ onClose, onConfirm, data }) => {

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className="overlay-black"></div>
    <div className="modal modal-event">
      <div className='modal-close' onClick={() => { localStorage.setItem('christmas_event', 0); onClose(); }}>x</div>
      <img src={EventImg} alt="" />
    </div>
  </>
}

export default EventModal;